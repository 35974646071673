/*
Version 0.167

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2019.

!Changelog

2019
	19-07-2019
	- [CHANGED] setPosition now first checks scrollTop when modal is higher than screenheight.
	17-07-2019
	- [CHANGED] Fixed a bug with setPosition.
	05-07-2019
	- [CHANGED] Fixed a bug with setPosition.
	02-07-2019
	- [CHANGED] setPosition Method is changed, even better now.
	30-05-2019
	- [CHANGED] setPosition Method is changed, better now.
	05-06-2019
	- [CHANGED] Again changed the setPosition to js-modal now.
	31-05-2019
	- [CHANGED] Modal width and height on setPosition are working again.
	24-05-2019
	- [ADDED] GO_LOAD 1 for XHR load.
	- [CHANGED] Moved the listeners for on and off to the open and close methods.
2018
	27-07-2018
	- [CHANGE] Changed the URL for Modals to /m/ instead of /p/
	19-05-2018
	- [CHANGE] Some bugs fixed; an error can now be HTML or an URL. 
	- [CHANGE] When closing the Modal in a FormProblem, then the form is going back in original state for submitting again.
2017
	10-10-2017
	- [BORN] This class is born. Hooray!

!Usage

	modal.open();
	modal.close();
	
!Wishlist

	none
 
*/

var modal = {};

(function(){
	
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************

	// none
	 
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	
	this.onready = function() {
		
		
		
	};

	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	
	/**
	Open Modal
	**/
	this.open = function(url,ref,message) {
		
		/*
		Listener: Click
		*/
		$(document).on('click','.js-modal-close',function(e) {
			modal.close();
		});
		
		/**
		Bind Listeners: Close Popup on click and keypress: ESC
		**/
		$(document).on("keyup.closePopup",function(e) {
			if (e.keyCode == 27) {
				modal.close();				
			}
		});
		
		/**
		Check if call is an ERROR
		**/
		if(url === 'error') {
			
			isModalOpenOnFormProblem = ref;
			
			$('.js-modal-background').fadeIn(300);
			
			/**
			Open modal, message is HTML
			**/
			$('.js-modal-loader').html(message).promise().done(function() {
					
				/**
				Set: isModalOpen
				**/
				isModalOpen = 1;
				
				/**
				Trigger: setPosition
				**/
				modal.setPosition();
				
			});
			
		} else {
		
			/**
			Set variables
			**/
			if(!ref) {
				ref = '';
			}
			
			if(!message) {
				message = '';
			}
			
			/**
			Build URL
			**/
			var modalUrl;
			if(_languagesCounter > 1) {
				modalUrl = globalUrl + '/' + _language + '/a/m/' + url;
			} else {
				modalUrl = globalUrl + '/a/m/' + url;	
			}
			
			/**
			Open modal
			**/
			if(isModalOpen === 0) {
				
				$('.js-modal-background').fadeIn(300);
				
				/**
				Send data by POST call and open popup on success
				**/		
				$.ajax({
				  url: modalUrl,
				  type: 'POST',
				  data: {
					  i:ref,
					  e:message
				  },
				  headers: 	{ 'GO_LOAD':1 },
				  success: function(html) {
					  
					/**
					Load the popup
					**/
					$('.js-modal-loader').html(html).promise().done(function() {
						
						/**
						Set: isModalOpen
						**/
						isModalOpen = 1;
						
						/**
						Trigger: setPosition
						**/
						modal.setPosition();
						
						/**
						Show scrollbar
						**/
						$('body').css({'overflow':'hidden'});
						
					});
					
				  },
				  error: function(e) {
					
					// error
					
				  }
				});
				
			}
		
		}
		
	};
	
	/**
	Close Modal
	**/
	this.close = function(
		formProblem
	) {
		
		/*
		Stop listener
		*/
		$(document).off('click','.js-modal-close');
		
		/**
		Close modal
		**/
		if(isModalOpen === 1) {
			
			$('.js-modal-background').fadeOut(300);
			$('.js-modal-loader').fadeOut(200, function() {
				
				/**
				Empty the .popup-loader container
				**/
				$('.js-modal-loader').empty();
				
				/**
				Show scrollbar
				**/
				$('body').css({'overflow':'auto'});
				
			});
			
			/**
			Process interaction: change button text back to original
			**/
			if(isModalOpenOnFormProblem !== 0) {
			
				var _getSubmitText = $('#' + isModalOpenOnFormProblem).find('.form-submit').data('texttosubmit');
				$('#' + isModalOpenOnFormProblem).find('.js-form-submit').removeClass('active').html(_getSubmitText);
				$(document).find('.submitbutton_' + isModalOpenOnFormProblem + '.js-form-specificsubmit').removeClass('active');
				$(document).find('.submitbutton_' + isModalOpenOnFormProblem + '.js-form-specificsubmit').children('.label').html(_getSubmitText);
			
			isModalOpenOnFormProblem = 0;
			
			}
			
			/**
			Set: isPopupOpen var to 0
			**/				
			isModalOpen = 0;
			
		}
		
		/**
		Trigger: modal interaction
		**/
		specific_interactions.modal('close');
		
	};
	
	/**
	Positioning the popups by settings
	**/
	this.setPosition = function() {
		
		/**
		Set modal offscreen
		**/
		$('.js-modal-loader').css({
			'left':-9999
		}).show().promise().done(function() {
		
			/**
			Is modal open?
			**/
			if(isModalOpen === 1) {
			
				/**
				Get width and height
				**/
				var modalHeight = $('.js-modal-loader').height();
				var modalWidth 	= $('.js-modal-loader').width();
				
				/**
				Hide
				**/
				$('.js-modal-loader').hide();
				
				/**
				Set scrolling on heights
				**/
				if(modalHeight > screenHeight) {
					
					if(
						modalHeight > screenHeight
					) {
						
						$('.js-modal-loader').css({
							'position'		: 'absolute',
							'top'			: $(document).scrollTop() + 40,
							'left'			: (screenWidth/2),
							'margin-left'	: -(modalWidth/2),
							'margin-top'	: 'auto'
						});
						
						/**
						Show scrollbar
						**/
						setTimeout(function() {
							$('body').css({'overflow':'auto'});
						},200);
					
					} else {
						
						$('.js-modal-loader').css({
							'position'		: 'fixed',
							'top'			: (screenHeight/2),
							'margin-top'	: -(modalHeight/2),
							'left'			: (screenWidth/2),
							'margin-left'	: -(modalWidth/2)
						});
						
						/**
						Show scrollbar
						**/
						$('body').css({'overflow':'auto'});
						
					}
					
				} else {
					
					$('.js-modal-scroller').css({
						'overflow':'hidden'
					});
		
					/*
					Set: Position fixed now, because height of popup is higher then document view
					*/
					$('.js-modal-loader').css({
						'position'		: 'fixed',
						'top'			: (screenHeight/2),
						'margin-top'	: -(modalHeight/2),
						'left'			: (screenWidth/2),
						'margin-left'	: -(modalWidth/2)
					});
					
					/**
					Show scrollbar
					**/
					$('body').css({'overflow':'hidden'});
				}
				
				/**
				Show the modal
				**/
				$('.js-modal-loader').show();
				
			}
		
		});
		
	};
		
}).apply(modal);